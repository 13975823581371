import React from 'react';
import styled from 'styled-components';

import Heading from '../Atoms/Heading';
import { formatTypeString } from '../../utilis/helpers';
import Box from '../Atoms/Box';
import Paragraph from '../Atoms/Paragraph';

const ContentParagraph = styled(Paragraph)`
  overflow-wrap: break-word;
`;

function SideContent({ type, sideContent }) {
  return (
    <>
      <Heading pl={2} pt={3} h={3}>{formatTypeString(type)}</Heading>
      {sideContent && (
        <Box pl={2} pt={3}>
          {sideContent.map(({ title: t, content: cont }) => {
            const contentArr = cont ? cont.split(/\r?\n/) : [];
            return (
              <Box pb={5}>
                <Heading h={3}>{t || ''}</Heading>
                {contentArr && contentArr.map(c => (
                  <>
                    {!c && <ContentParagraph />}
                    {c && c !== '' && <ContentParagraph pb={0}>{c}</ContentParagraph>}
                  </>
                ))}
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
}

export default SideContent;
