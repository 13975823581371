import React, { Component } from 'react';
import get from 'lodash/get';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import styled from 'styled-components';
import ReactModal from 'react-modal';
import Section from '../Atoms/Section';
import theme from '../../styles/theme';
import Container from '../Atoms/Container';
import Row from '../Atoms/Row';
import Column from '../Atoms/Column';
import Heading from '../Atoms/Heading';
import Button from '../Atoms/Button';
import { formatTypeString } from '../../utilis/helpers';
import FlexBox from '../Atoms/FlexBox';
import SideContent from './SideContent';

const StyledFlexBox = styled(FlexBox)`
  background-color: ${props => props.theme.color.white};
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
`;

@observer
class ArticleHeader extends Component {
  @observable isModalOpen = false;

  // modal function
  @action.bound
  handleOpenModal() {
    this.isModalOpen = true;
  }

  @action.bound
  handleCloseModal() {
    this.isModalOpen = false;
  }

  render() {
    const {
      bgImage, title, subtitle, type, sideContent, ...rest
    } = this.props;
    return (
      <>
        <Section
          pt={8}
          pb={6}
          background={`url(${get(bgImage, 'image.childImageSharp.fluid.src', get(bgImage, 'image', ''))})`}
          backgroundSize="cover"
          backgroundPosition="center"
          {...rest}
        >
          <Container>
            <Row>
              <Column>
                <Heading h={4} color={theme.color.white}>
                  {subtitle}
                </Heading>
                <Heading h={1} color={theme.color.white} pb={5}>
                  {title}
                </Heading>
                {sideContent && (
                  <StyledFlexBox display={['flex', 'flex', 'none']} p={3}>
                    <Heading h={3}>
                      {formatTypeString(type)}
                    </Heading>
                    <Button primary outlined width={120} ml="auto" text="details" onClick={this.handleOpenModal} />
                  </StyledFlexBox>
                )}
              </Column>
            </Row>
            <Row />
          </Container>
        </Section>
        {sideContent && (
        <ReactModal
          closeTimeoutMS={120}
          isOpen={this.isModalOpen}
          contentLabel="modal"
          onRequestClose={this.handleCloseModal}
          style={{
            overlay: {
              display: 'flex',
              backgroundColor: 'rgba(0,0,0, 0.25)',
              alignItems: 'flex-start',
              justifyContent: 'center',
            },
            content: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              position: 'relative',
              padding: '16px',
              width: '100%',
              height: '100%',
            },
          }}
        >
          <Container p={4}>
            <Row alignItems="center" width={240}>
              <Column pt={6}>
                <SideContent type={type} sideContent={sideContent} />
                <FlexBox width="100%">
                  <Button primary outlined text="close" onClick={this.handleCloseModal} />
                </FlexBox>
              </Column>
            </Row>
          </Container>
        </ReactModal>
        )}
      </>
    );
  }
}

export default ArticleHeader;
