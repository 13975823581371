import React, { Fragment } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import moment from 'moment';
import Content, { HTMLContent } from '../components/Atoms/Content';
import Box from '../components/Atoms/Box';
import Section from '../components/Atoms/Section';
import Container from '../components/Atoms/Container';
import Row from '../components/Atoms/Row';
import Column from '../components/Atoms/Column';
import Paragraph from '../components/Atoms/Paragraph';
import theme from '../styles/theme';
import Heading from '../components/Atoms/Heading';
import { PageHelmet } from '../components/PageHelmet';
import { formatTypeString } from '../utilis/helpers';
import ArticleHeader from '../components/Articles/ArticleHeader';
import SideContent from '../components/Articles/SideContent';

const ColumnWithShadow = styled(Column)`
  background: white;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
`;

export const ArticlePostTemplate = ({
  content,
  contentComponent,
  description = '',
  title,
  subtitle,
  date,
  type,
  bgImage,
  sideContent,
}) => {
  const PostContent = contentComponent || Content;

  let formattedDate = date;

  if (typeof date !== 'string') {
    formattedDate = moment(date).format('DD MMMM YYYY');
  }

  return (
    <>
      <PageHelmet titleTemplate="%s | News" title={title} description={description} />
      <ArticleHeader
        bgImage={bgImage}
        backgroundColor={theme.color.blue}
        minHeight={280}
        subtitle={formatTypeString(type)}
        title={title}
        type={type}
        sideContent={sideContent}
      />
      <Section pb={7}>
        <Container>
          <Row justifyContent="center">
            <ColumnWithShadow mt={-48} col="is-12">
              <Row>
                {sideContent && (
                  <Column col="is-3" display={['none', 'none', 'block']}>
                    <SideContent type={type} sideContent={sideContent} />
                  </Column>
                )}
                <Column col={sideContent ? 'is-8' : 'is-12'} px={sideContent ? 0 : [3, 3, 5]}>
                  {/*    /!* blog post header *!/ */}
                  <Box>
                    <Heading h={4} color={theme.color.black80} pt={3}>
                      {formattedDate}
                    </Heading>
                    <Heading h={1}>{title}</Heading>
                    {subtitle && <Paragraph color={theme.color.black80}>{subtitle}</Paragraph>}
                  </Box>
                  {/* blog post content */}
                  <Box className="content" minHeight={400}>
                    <PostContent content={content} />
                  </Box>
                </Column>
              </Row>
            </ColumnWithShadow>
          </Row>
        </Container>
      </Section>
    </>
  );
};

const ArticlePost = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <ArticlePostTemplate content={post.html} contentComponent={HTMLContent} {...post.frontmatter} />
  );
};

export default ArticlePost;

export const pageQuery = graphql`
  query ArticlePostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      htmlAst
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        title
        subtitle
        group
        type
        author
        description
        sideContent {
          title
          content
        }
        bgImage {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
